import styled, { keyframes } from "styled-components/macro";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
  `;

export const Overlay = styled.div`
  animation: ${fadeIn} 250ms ease-in-out;
  background: ${({ backgroundColor }) =>
    backgroundColor || "var(--color-neutral-black)"};
  inset: 0;
  opacity: 0.75;
  overflow: auto;
  position: fixed;
  z-index: ${({ zIndex }) => (zIndex >= 0 ? zIndex : 15)};
`;
