export const routes = Object.freeze({
  DS4A_APPLICATION: "/ds4a-application",
  LOGIN: "/login",
  VERIFY_EMAIL: "/verify-email",
  SIGNUP: "/signup",
  ACTIVATE: "/activate",
  PASSWORD_RESET: "/password-reset",
  PASSWORD_RESET_REQUEST: "/password-reset-request",
  EVENTS: "/events",
  COURSE_MATERIALS: (slug = ":slug") => `/my-programs/${slug}/course-materials`,
  SUBMISSIONS: (slug = ":slug") => `/events/${slug}/submissions`,
  RESOURCES: (slug = ":slug") => `/events/${slug}/resources`,
  FORUM: "/ds4a/forum",
  DIRECTORY: (slug = ":slug") => `/events/${slug}/directory`,
  WORKSPACE: (slug = ":slug") => `/events/${slug}/workspace`,
  PROFESSIONAL_DEVELOPMENT: (slug = ":slug") =>
    `/events/${slug}/professional-development`,
  LOGOUT: "/logout",
  HOME: "/my-programs",
});
