import styled from "styled-components/macro";
import { Container } from "components/Atoms/Containers/Containers";
import { H5 } from "components/Atoms/Headings/Headings";
import { HFlex, VFlex } from "components/Atoms/Layouts/Flex";
import { Text } from "components/Atoms/Text/Text";
import { Link } from "components/Blocks/Link/Link";
import { unexpectedErrorImage } from "assets/unexpectedErrorImage";

const FallbackComponent = () => {
  return (
    <LayoutContainer align={"center"} justify={"center"}>
      <VFlex justify={"center"} align={"center"} gap={"16"}>
        <H5>{"Something went wrong!"}</H5>
        <ErrorImageContainer role="presentation" fullWidth>
          {unexpectedErrorImage}
        </ErrorImageContainer>
        <VFlex align={"center"} gap={"8"} justify={"center"}>
          <HFlex align={"baseline"} gap={"8"} justify={"center"}>
            <Text variant="body">
              {"Contact your program's operations member or "}
            </Text>
            <Link
              href={"mailto:support@ds4a.io"}
              isExternal
              isIconHidden
              textTransform={"lowercase"}
            >
              {"support@ds4a.io"}
            </Link>
          </HFlex>
          <HFlex align={"baseline"} gap={"8"} justify={"center"}>
            <Text variant="body">{"Go back to the "}</Text>
            <Link as={"a"} href={"/"}>
              {"Home page"}
            </Link>
          </HFlex>
        </VFlex>
      </VFlex>
    </LayoutContainer>
  );
};

export { FallbackComponent };

const LayoutContainer = styled(VFlex)`
  background: var(--color-primary-gradient);
  color: var(--color-neutral-white);
  height: 100%;
  min-height: 100vh;
  position: relative;
  text-align: center;
`;

const ErrorImageContainer = styled(Container)`
  max-width: 500px;
  width: 100%;
`;
