export const mockSupportData = {
  teaching_assistant_support: {
    profiles: [
      {
        id: 1,
        bio: "Experienced TA with a background in computer science.",
        full_name: "Alice Johnson",
        meeting_link: "https://meetinglink.com/alice",
        email: "alice.johnson@domain.com",
        pronouns: "she/her",
        profile_picture: null,
        slack_link: "https://slack.com/alice",
        linkedin_profile: "https://linkedin.com/in/alicejohnson",
      },
      {
        id: 2,
        bio: "Passionate about teaching and helping students succeed.",
        full_name: "Bob Smith",
        meeting_link: "https://meetinglink.com/bob",
        email: "bob.smith@domain.com",
        pronouns: "he/him",
        profile_picture: null,
        slack_link: "https://slack.com/bob",
        linkedin_profile: "https://linkedin.com/in/bobsmith",
      },
      {
        id: 3,
        bio: "Loves to make complex topics simple.",
        full_name: "Carol Williams",
        meeting_link: "https://meetinglink.com/carol",
        email: "carol.williams@domain.com",
        pronouns: "they/them",
        profile_picture:
          "https://t3.ftcdn.net/jpg/03/67/46/48/240_F_367464887_f0w1JrL8PddfuH3P2jSPlIGjKU2BI0rn.jpg",
        slack_link: "https://slack.com/carol",
        linkedin_profile: "https://linkedin.com/in/carolwilliams",
      },
    ],
    round_robin_link: "https://roundrobinlink.com/ta",
    is_support_active: true,
  },
  career_success_coach_support: {
    profiles: [
      {
        id: 4,
        bio: "Career coach with over 10 years of experience.",
        full_name: "David Brown",
        meeting_link: "https://meetinglink.com/david",
        email: "david.brown@domain.com",
        pronouns: "he/him",
        profile_picture:
          "https://media.istockphoto.com/id/1300512215/photo/headshot-portrait-of-smiling-ethnic-businessman-in-office.jpg?s=612x612&w=0&k=20&c=QjebAlXBgee05B3rcLDAtOaMtmdLjtZ5Yg9IJoiy-VY=",
        slack_link: "https://slack.com/david",
        linkedin_profile: "https://linkedin.com/in/davidbrown",
      },
      {
        id: 5,
        bio: "Specializes in resume building and interview preparation.",
        full_name: "Emma Davis",
        meeting_link: "https://meetinglink.com/emma",
        email: "emma.davis@domain.com",
        pronouns: "she/her",
        profile_picture: null,
        slack_link: "https://slack.com/emma",
        linkedin_profile: "https://linkedin.com/in/emmadavis",
      },
      {
        id: 6,
        bio: "Helps fellows navigate their career paths effectively.",
        full_name: "Frank Miller",
        meeting_link: "https://meetinglink.com/frank",
        email: "frank.miller@domain.com",
        pronouns: "he/him",
        profile_picture: null,
        slack_link: "https://slack.com/frank",
        linkedin_profile: "https://linkedin.com/in/frankmiller",
      },
    ],
    round_robin_link: "https://roundrobinlink.com/csc",
    is_support_active: true,
  },
};
